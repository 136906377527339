.App {

  text-align: center;
  min-height: 100%;
  min-width: 100%;
  word-wrap: break-word;
  -webkit-text-stroke: #000 medium;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.notificationContainer {
  /* display: fixed; */
  height: fit-content;
  z-index: 10;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

#notification {
  display: block !important;
  height: 4em;
  overflow: hidden;
  align-content: center;
  background-color: #fff;
  border-bottom: solid thin #d5d5d5;
  color: white;
  text-align: center;
  font-size: 1em;
  -webkit-text-stroke-width: thin;
  transition: all ease-out .5s;
}

#donationAddress {

  transform-style: preserve-3d;
  transition: all ease-out 1s;
  transform-origin: left;
  font-size: 1.1em;
  -webkit-text-stroke: #000 thin;

}


#donationAddressQRContainer {

  cursor: pointer;
  transform-style: preserve-3d;
  margin-top: 3em;

}

#donationAddressQR {

  cursor: pointer;
  transform-style: preserve-3d;
  transition: all ease-in .75s;
  border: solid medium gold;
  height: 5em;

}

@media screen and (max-width:480px) {

  #donationAddressQR {

    cursor: pointer;
    transform-style: preserve-3d;
    transition: all ease-in .75s;
    border: solid medium gold;
    height: 8em;

  }

}

#donationAddressQRContainer:focus #donationAddressQR {

  transform: translateY(-5%) scale(1.25) rotateY(360deg);

}

#donationAddressQRContainer:hover #donationAddressQR {

  transform: translateY(-5%) scale(1.25) rotateY(360deg);

}

#donationAddress:focus {

  cursor: pointer;
  color: #f7931a;

}

#donationAddress:hover {

  cursor: pointer;
  color: #f7931a;

}

.messageToSignContainer {

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  padding-bottom: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* max-width: 100vw; */
  min-height: 100vh;
  width: 100%;
  height: 150vh;
  /* background-color: #282c34; */
  background-image: URL('../public/bgimg.png');
  /* top: 30%; */
  background-size: cover;
  background-position: center;
  color: #fff;
  word-wrap: break-word;

}

#messageToSign {

  display: block;
  width: 75%;
  min-width: 50vw;
  max-width: 80vw;
  font-size: 1em;
  min-height: 5em;
  height: fit-content;
  border: solid thin #fff;
  background-color: #fff;
  color: #f7931a;
  border-radius: .5em;
  font-weight: bold;
  text-align: center;
  align-content: center;
  margin: 2em;
  -webkit-text-stroke-width: thin;
  /* red thumb color */

}

#messageToSign:focus {
  border: solid .175em #f7931a;
  /* adjust the scrollbar width */
}

#messageToSign:hover {
  border: solid .175em #f7931a;
  /* adjust the scrollbar width */
}

#messageToSign::-webkit-scrollbar {
  width: 10px;
  /* adjust the scrollbar width */
}

#messageToSign::-webkit-scrollbar-thumb {
  background-color: #f7931a;
  /* set the thumb color */
  border-radius: .125em;
  /* adjust the thumb shape */
  height: 2em;
}

#messageToSign::-webkit-scrollbar-track {
  background-color: #fff;
  /* set the track color */
}


#messageToSignLabel {

  display: block;
  font-size: 3em;
  max-width: 80vw;
  color: #f7931a;
  font-weight: bold;
  -webkit-text-stroke: #000 thin;

}

#requestSignSubmit {

  display: block;
  cursor: pointer;
  width: 20%;
  font-size: 1em;
  font-weight: bold;
  border: solid thin #000;
  color: #f7931a;
  background-color: #fff;
  border-radius: .25em;
  padding: .5em;
  margin-bottom: 2em;
  -webkit-text-stroke-width: thin;

}

#requestSignSubmit:focus {

  background-color: #f7931a;
  border-color: #fff;
  color: #fff;
  text-decoration: #F2A900;

}

#requestSignSubmit:hover {

  background-color: #f7931a;
  border-color: #fff;
  color: #fff;
  text-decoration: #F2A900;

}

#changeFunctionIcon {

  transform-style: preserve-3d;
  margin-left: 1em;

}

.signatureContainer {
  
  position: fixed;
  overflow: hidden;
  height: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  max-width: 100vw;
  z-index: 10;
  align-content: center;
  font-size: 2em;
  /* background-color: #d5d5d5; */
  background-image: URL('../public/bgimg.png');
  /* top: 30%; */
  background-size: cover;
  background-position: center;
  color: #f7931a;
  
}
.signatureContainer #signature:hover {

  cursor: pointer;

}

/* #changeFunctionIcon:hover {

  transform: rotate(360deg);

}

.rotate:hover {

  animation: rotation 1s infinite linear;

} */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {

  font-family: fantasy;
  -webkit-text-stroke-width: 1px;
  
}

#bitcoinCharSpan {
  
  -webkit-text-stroke-width: 4px;

}

@media screen and (max-width: 580px) {

  body {

    font-family: fantasy;
    -webkit-text-stroke-width: 1px;

  }

  #notification {

    /* font-size: 1.25em; */
    /* transform: scale(1.2); */

  }

  #donateContainer {

    font-size: 1.25em;
    /* transform: scale(1.5); */

  }

  #donationAddress {

    /* font-size: 1.5em; */
    /* transform: scale(1.5); */

  }

  #messageToSignLabel {

    /* font-size: 2.5em; */
    /* transform: scale(2); */

  }

}