body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
  /* adjust the scrollbar width */
}

body::-webkit-scrollbar-thumb {
  background-color: #f7931a; /* set the thumb color */
  border-radius: .125em; /* adjust the thumb shape */
  /* height: 1em; */
}

body::-webkit-scrollbar-track {
  background-color: #fff; /* set the track color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
