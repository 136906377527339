.chooseProviderContainer {

    position: fixed;
    overflow: hidden;
    height: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    max-width: 100vw;
    z-index: 10;
    align-content: center;
    font-size: 2em;
    /* background-color: #d5d5d5; */
    background-image: URL('../../../public/bgimg.png');
    /* top: 30%; */
    background-size: cover;
    background-position: center;
    color: #f7931a;

}

.providersContainer {

    margin-top: 1em;
    height: 12em;
    border: thin solid #fff;
    overflow-x: hidden;
    flex-direction: column;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    /* margin-right: 1em; */

}

.providersContainer::-webkit-scrollbar {
    width: 10px;
    /* adjust the scrollbar width */
}

.providersContainer::-webkit-scrollbar-thumb {
    background-color: #f7931a;
    /* set the thumb color */
    border-radius: .065em;
    /* adjust the thumb shape */
    height: 2em;
}

.providersContainer::-webkit-scrollbar-track {
    background-color: #fff;
    /* set the track color */
}

#provider {

    margin: 1em;
    border: thin solid #fff;
    border-radius: .25em;
    padding: 1em;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #fff;

}

.chooseProviderContainer #provider:hover {

    color: #f7931a;
    background: #fff;
    cursor: pointer;

}

@media screen and (max-width: 580px) {

    .providersContainer {
        border-radius: .5em;
        padding: .5em;
        margin: 0;
    }

    .providersContainer {
        border-radius: .5em;
        padding: .5em;
    } 

    #provider {
        
    }

}